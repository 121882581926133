import { useEventListener } from '@rocketmakers/armstrong-edge'
import * as React from 'react'

const MousePositionContext = React.createContext<{ clientX: number; clientY: number }>({ clientX: 0, clientY: 0 })

export const useMousePosition = () => React.useContext(MousePositionContext)

export const MousePositionProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [clientX, setClientX] = React.useState(0)
  const [clientY, setClientY] = React.useState(0)

  const onMouseMove = React.useCallback((event: React.MouseEvent) => {
    setClientX(event.clientX)
    setClientY(event.clientY)
  }, [])

  useEventListener('mousemove', onMouseMove)

  return <MousePositionContext.Provider value={{ clientX, clientY }}>{children}</MousePositionContext.Provider>
}
