import { GatsbyBrowser } from 'gatsby'
import * as React from 'react'

import { Shell } from './components/shell'
import { MousePositionProvider } from './hooks/useMousePosition'

export const Providers: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <MousePositionProvider>
      <Shell>{children}</Shell>
    </MousePositionProvider>
  )
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return <Providers>{element}</Providers>
}
