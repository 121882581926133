module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"15-love-label-website","accessToken":"MC5aZW9CX0JBQUFDTUFOb2E5.77-977-977-977-9He-_vT_vv73vv73vv73vv71E77-9WO-_ve-_vUHvv73vv71HfhcU77-9eDUH77-9Gu-_vWHvv70"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"15 Love","short_name":"15 Love","start_url":"/","background_color":"#fc981c","theme_color":"#fc981c","display":"standalone","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"69ea084f15675e1175b56039af6fc239"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
